<script>
export default {
  emits: ["click"],
  setup(props, { emit }) {
    const click = () => {
      emit("click");
    };

    return { click };
  },
};
</script>

<template>
  <button class="base-button" type="button" @click="click">
    <slot>Submit</slot>
  </button>
</template>
