<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import ActivityEventItem from "./ActivityEventItem.vue";
export default defineComponent({
  components: { ActivityEventItem },
  props: {
    activity: {
      type: Array,
      required: false,
    },
  },

  setup(props) {
    let events = computed(() => props.activity);

    if (!events.value) {
      const store = useStore();
      store.dispatch("activity/getActivity");
      events = computed(() => store.getters["activity/recent"]);
    }

    return {
      events,
    };
  },
});
</script>

<template>
  <ul class="space-y-4">
    <li v-for="event in events" :key="event.id">
      <base-card class="px-3 py-3 text-xs bg-white">
        <ActivityEventItem :event="event" />
      </base-card>
    </li>
  </ul>
</template>
