import Signup from "@/modules/auth/pages/Signup.vue";
import Login from "@/modules/auth/pages/Login.vue";

export const AuthRoutes = [
  {
    path: "/signup",
    name: "signup",
    component: Signup,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
];
