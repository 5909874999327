import { createRouter, createWebHistory } from "vue-router";
import { ActivityRoutes } from "@/modules/activity/routes/ActivityRoutes";
import { AuthRoutes } from "@/modules/auth/routes/AuthRoutes";
import { ListRoutes } from "@/modules/lists/routes/ListRoutes";
import { UserRoutes } from "@/modules/users/routes/UserRoutes";
import { ProfileRoutes } from "@/modules/profile/routes/ProfileRoutes";

import Home from "@/pages/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: routes
    .concat(ActivityRoutes)
    .concat(AuthRoutes)
    .concat(ListRoutes)
    .concat(UserRoutes)
    .concat(ProfileRoutes),
});

export default router;
