<template>
  <div class="min-h-screen bg-gray-100">
    <div class="bg-indigo-600 ">
      <base-header />
    </div>

    <router-view />
  </div>
  <div id="modals"></div>
</template>

<script>
import BaseHeader from "@/components/Layout/Header/BaseHeader";
import * as auth from "@/modules/auth/services/AuthService";
export default {
  components: { "base-header": BaseHeader },
  setup() {
    auth.init();
  },
};
</script>

<style lang="postcss">
@import url("https://fonts.googleapis.com/css2?family=Grandstander:wght@100;200;300;400;500;600;700&family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500&display=swap");
</style>
