<template>
  <div class="flex items-center">
    <!-- On: "bg-indigo-600", Off: "bg-gray-200" -->
    <button
      type="button"
      aria-pressed="false"
      aria-labelledby="toggleLabel"
      class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      :class="[
        (invert && !modelValue) || (!invert && modelValue)
          ? 'bg-yellow-400'
          : 'bg-gray-200 ',
      ]"
      @click="handleToggle"
    >
      <span class="sr-only">Use setting</span>
      <!-- On: "translate-x-5", Off: "translate-x-0" -->
      <span
        aria-hidden="true"
        class="inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        :class="[
          (invert && !modelValue) || (!invert && modelValue)
            ? 'translate-x-5'
            : 'translate-x-0 ',
        ]"
      ></span>
    </button>
    <span class="ml-3" id="toggleLabel">
      <span class="text-sm font-medium text-gray-900">{{ label }}</span>
      <span class="text-sm text-gray-500">{{ description }}</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
    invert: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const handleToggle = () => emit("update:modelValue", !props.modelValue);
    return { handleToggle };
  },
};
</script>
