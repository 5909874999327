<template>
  <base-content>
    <template #workarea>
      <base-card class="max-w-screen-sm my-8 bg-green-50">
        <h1
          class="text-left text-3xl  font-semibold border-green-600 text-green-600 pb-4 mb-4 border-b-2 "
        >
          Sign Up
        </h1>
        <p class="mb-4">
          <span class="font-medium text-green-600">Sign up is free!</span><br />
          Sign up to save your lists, and interact with other's lists.
        </p>
        <form
          @submit.prevent="signup"
          class="flex flex-col  relative max-w-screen-sm mx-auto"
        >
          <div class="md:flex md:space-x-2 ">
            <div class="field flex-1">
              <label for="firstname">First name</label>
              <input type="text" id="firstname" v-model="firstname" />
            </div>

            <div class="field flex-1">
              <label for="lastname">Last name</label>
              <input type="text" id="lastname" v-model="lastname" />
            </div>
          </div>

          <div class="field"></div>

          <div class="md:flex md:space-x-2 ">
            <div class="field flex-1">
              <label for="email">Email</label>
              <input type="email" id="email" v-model="email" />
            </div>

            <div class="field flex-1">
              <label for="user">Username</label>
              <input type="text" id="username" v-model="username" />
            </div>
          </div>

          <div class="md:flex md:space-x-2 ">
            <div class="field flex-1">
              <label for="password">Password</label>
              <input type="password" id="password" v-model="password" />
            </div>

            <div class="field flex-1">
              <label for="passwordConfirm">Confirm Password</label>
              <input
                type="password"
                id="passwordConfirm"
                v-model="passwordConfirm"
              />
            </div>
          </div>
          <p v-if="error" class="text-red-500 text-sm">
            <strong>Error:</strong> {{ error }}
          </p>
          <base-button type="submit" class="mt-2">Sign Up</base-button>
        </form>
      </base-card>
    </template>
  </base-content>
</template>

<script>
import { ref } from "vue";
import * as auth from "@/modules/auth/services/AuthService";
import { useRouter } from "vue-router";

export default {
  setup() {
    const firstname = ref("");
    const lastname = ref("");
    const email = ref("");
    const username = ref("");
    const password = ref("");
    const passwordConfirm = ref("");
    const error = ref("");
    const router = useRouter();

    const validate = async () => {
      error.value = "";
      if (
        !email.value ||
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email.value
        ) === false
      ) {
        error.value = "Email Address Invalid";
        return false;
      }

      if (firstname.value.length === 0) {
        error.value = "Enter a first name";
        return false;
      }

      if (lastname.value.length === 0) {
        error.value = "Enter a last name";
        return false;
      }

      if (password.value.length < 8) {
        error.value = "Enter a strong password";
        return false;
      }

      if (username.value.length === 0) {
        error.value = "Enter a username";
        return false;
      }

      if (password.value !== passwordConfirm.value) {
        error.value = "Passwords don't match";
        return false;
      }

      try {
        if (await auth.usernameExists(username.value)) {
          error.value = "Username already exists";
          return false;
        }
      } catch (err) {
        error.value = err;
        return false;
      }

      return true;
    };

    const signup = async () => {
      if (await validate()) {
        try {
          await auth.signup(
            email.value,
            username.value,
            password.value,
            firstname.value,
            lastname.value
          );

          router.push("/lists");
        } catch (err) {
          error.value = err.message;
        }
      }
    };

    return {
      email,
      username,
      password,
      passwordConfirm,
      firstname,
      lastname,
      signup,
      error,
    };
  },
};
</script>
