<template>
  <base-content>
    <template #title>
      <h1 class=" relative mt-0">Recent Activity</h1>
    </template>

    <ActivityLog :activity="activity" />
  </base-content>
</template>

<script>
import ActivityLog from "../components/ActivityLog.vue";

export default {
  components: {
    ActivityLog,
  },
};
</script>
