<template>
  <base-content>
    <template #workarea>
      <EditProfile />
      <ChangePassword />
    </template>
  </base-content>
</template>

<script>
import EditProfile from "../components/EditProfile.vue";
import ChangePassword from "../components/ChangePassword.vue";

export default {
  components: { EditProfile, ChangePassword },
};
</script>
