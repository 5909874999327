import he from "he";

const GiftService = {
  fetchGiftData: async function(url) {
    const giftData = {
      name: null,
      image: null,
      url: url,
    };

    const res = await fetch("https://z.gift.town/?url=" + encodeURI(url), {
      referer: document.location,
      "Access-Control-Allow-Origin": "*",
    });

    const data = await res.json();

    if (data.images.best.length) {
      giftData.image = data.images.best[0];
    }

    if (data.title["og:title"]) {
      giftData.name = data.title["og:title"];
    } else if (data.title["title"]) {
      giftData.name = data.title["title"];
    } else if (data.title["h1"] && data.title["h1"].length > 0) {
      giftData.name = data.title["h1"][0];
    }

    // Should this be moved to the API, "he" is pretty heavy?
    giftData.name = he.decode(giftData.name);

    return giftData;
  },
};

export default GiftService;
