<template>
  <div>
    <div class="bg-yellow-100 pb-32">
      <header class="pt-10 pb-10" v-if="hasTitle">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <slot name="title"></slot>
        </div>
      </header>
    </div>

    <main class="-mt-32">
      <slot name="workarea">
        <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
          <!-- Replace with your content -->
          <div
            id="workarea"
            class="bg-white rounded-lg shadow px-5 py-6 sm:px-6"
          >
            <slot></slot>
          </div>
          <!-- /End replace -->
        </div>
      </slot>
    </main>
  </div>
</template>

<script>
export default {
  setup(props, { slots }) {
    const hasTitle = !!slots.title;

    return {
      hasTitle,
    };
  },
};
</script>

<style lang="postcss" scoped>
#workarea {
  background-image: linear-gradient(
      theme("colors.yellow.100") 2.2px,
      transparent 2.2px
    ),
    linear-gradient(90deg, theme("colors.yellow.100") 2.2px, transparent 2.2px),
    linear-gradient(theme("colors.yellow.100") 1.1px, transparent 1.1px),
    linear-gradient(
      90deg,
      theme("colors.yellow.100") 1.1px,
      theme("colors.yellow.50") 1.1px
    );
  background-size: 55px 55px, 55px 55px, 11px 11px, 11px 11px;
  background-position: -2.2px -2.2px, -2.2px -2.2px, -1.1px -1.1px,
    -1.1px -1.1px;
}
</style>
