import { createStore, createLogger } from "vuex";
import activity from "@/modules/activity/store/ActivityStore";
import lists from "@/modules/lists/store/ListStore";
import users from "@/modules/users/store/UsersStore";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  modules: {
    activity,
    lists,
    users,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [],
});
