<template>
  <div class="field">
    <label :for="id">{{ label }}</label>
    <input :type="type" :id="id" :value="modelValue" @input="handleInput" />
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const handleInput = (e) => emit("update:modelValue", e.target.value);
    return { handleInput };
  },
};
</script>

<style lang="postcss" scoped>
.field {
  @apply flex flex-col py-2;
  label {
    @apply mb-1 text-green-600 font-medium;
  }
  input {
    @apply p-2;
  }
}
</style>
