<script>
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { defineComponent } from "vue";
export default defineComponent({
  props: { event: Object },
  setup() {
    dayjs.extend(customParseFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(utc);

    return {
      dayjs,
    };
  },
});
</script>

<template>
  <router-link
    :to="{ name: 'lists', params: { username: event.data.username } }"
    class="font-semibold text-green-600"
  >
    @{{ event.user.username }}</router-link
  >
  <span v-if="event.type === 'gift' && event.action == 'added' && event.gift">
    added
    <strong class="font-semibold">{{ event.gift.data.name }}</strong>
    to
    <router-link
      :to="{
        name: 'list',
        params: {
          username: event.data.username,
          listSlug: event.list.slug,
        },
      }"
      class="font-semibold text-green-600"
      >{{ event.list.name }}</router-link
    >
  </span>
  <span
    v-else-if="
      event.type === 'gift' &&
        (event.action == 'claimed' || event.action == 'unclaimed')
    "
    >&nbsp;{{ event.action }}
    <strong class="font-semibold">{{ event.gift.data.name }}</strong>
    from
    <router-link
      :to="{ name: 'lists', params: { username: event.person.username } }"
      class="font-semibold text-green-600"
    >
      @{{ event.person.username }}&apos;s
    </router-link>
    <router-link
      :to="{
        name: 'list',
        params: {
          username: event.person.username,
          listSlug: event.list.slug,
        },
      }"
      class="font-semibold text-green-600"
      >{{ event.list.name }}</router-link
    >
  </span>
  <span v-else-if="event.type === 'list'"
    >&nbsp;{{ event.action === "added" ? "created list" : event.action }}

    <router-link
      :to="{
        name: 'list',
        params: {
          username: event.user.username,
          listSlug: event.list.slug,
        },
      }"
      class="font-semibold text-green-600"
      >{{ event.list.name }}</router-link
    >
  </span>
  <div class="text-yellow-400 italic">
    {{ dayjs().to(dayjs.utc(event.created_at).format()) }}
  </div>
</template>
