import { reactive, computed } from "vue";
import { useSupabase } from "@/services/supabase";

const state = reactive({
  initialized: false,
  user: null,
  session: null,
  following: [],
});

const setFollowing = (following) => {
  state.following = following;
};

const setUser = (user) => {
  state.user = user;
};

export const isLoggedIn = computed(() => {
  return state.user !== null;
});

export const currentUser = computed(() => {
  return state.user;
});

export const following = computed(() => {
  return state.following;
});

export const init = async () => {
  if (state.initialized) {
    console.log("already initialized");
    return;
  }
  console.log("do initialize");

  const supabase = await useSupabase();

  supabase.auth.onAuthStateChange(async (event, session) => {
    if (session === null) {
      setUser(null);
      setFollowing(null);
    }
  });

  const user = await supabase.auth.user();

  if (user) {
    const { follows, ...data } = await getUser(user.id);
    setUser(data);
    setFollowing(follows.map((user) => user.user));
  }

  state.initialized = true;
};

export const signup = async (
  email,
  username,
  password,
  firstname,
  lastname
) => {
  const supabase = await useSupabase();
  const { error, user } = await supabase.auth.signUp({
    email,
    password,
  });

  if (error) {
    throw new Error(error);
  }

  const id = user.id;
  const state = { id, email, username, firstname, lastname };

  setUser(state);

  await supabase.from("users").insert([state]);
};

export const updateProfile = async (email, username, firstname, lastname) => {
  const supabase = await useSupabase();
  let { data, error } = await supabase
    .from("users")
    .update({
      email,
      username,
      firstname,
      lastname,
    })
    .eq("id", supabase.auth.user().id)
    .single();

  if (error) {
    if (error.message) {
      error = error.message;
    }
    throw new Error(error);
  }

  setUser(data);
  return data;
};

export const changePassword = async (password) => {
  const supabase = await useSupabase();
  let { data, error } = await supabase.auth.update({ password });

  if (error) {
    if (error.message) {
      error = error.message;
    }
    throw new Error(error);
  }

  return data;
};

async function getUser(id) {
  const supabase = await useSupabase();
  let { data, error } = await supabase
    .from("users")
    .select(
      "id, email, username, firstname, lastname, follows!uid(follow_id, user:users!follow_id(*))"
    )
    .eq("id", id)
    .single();

  if (error) {
    throw new Error(error);
  }
  return data;
}

export async function follow(username) {
  const supabase = await useSupabase();
  let { data, error } = await supabase.rpc("follow_person", { username });

  if (error) {
    throw new Error(error);
  }

  if (data[0].username === username) {
    state.following.push(data[0]);
  }

  return data;
}

export async function unfollow(username) {
  const supabase = await useSupabase();
  let { error } = await supabase.rpc("unfollow_person", { username });

  if (error) {
    throw new Error(error);
  }

  state.following = state.following.filter(
    (user) => user.username !== username
  );

  return;
}

export const login = async (email, password) => {
  const supabase = await useSupabase();
  const { data, error } = await supabase.auth.signIn({
    email,
    password,
  });

  if (error) {
    throw new Error(error);
  }

  const { follows, ...user } = await getUser(data.user.id);
  setUser(user);
  setFollowing(follows.map((user) => user.user));
};

export async function logout() {
  const supabase = await useSupabase();
  const { error } = await supabase.auth.signOut();
  if (error) {
    throw new Error(error);
  }

  setUser(null);
  setFollowing([]);
}

export async function usernameExists(username) {
  const supabase = await useSupabase();
  const { data, error } = await supabase
    .from("users")
    .select("username")
    .ilike("username", username.trim());

  if (error) {
    throw new Error(error);
  }

  return data.length !== 0;
}
