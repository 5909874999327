import { useSupabase } from "@/services/supabase";

const state = () => {
  return {
    recent: [],
    follows: [],
  };
};

const getters = {
  recent(state) {
    return state.recent;
  },
  username(state) {
    return (username) =>
      state.recent.filter((event) => event.user.username === username);
  },
  follows(state) {
    return state.follows;
  },
};

const actions = {
  async getActivity({ commit }) {
    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("activity_with_resources")
      .select("*")
      .eq("type", "gift")
      .order("event_id", { ascending: false });

    if (error) {
      throw new Error(error);
    }

    commit("SET_RECENT", data);
  },

  async getFollowingActivity({ commit }) {
    const supabase = await useSupabase();
    const { data, error } = await supabase.rpc(
      "activity_with_resources_of_following"
    );

    if (error) {
      throw new Error(error);
    }

    commit("SET_FOLLOWS", data);
  },
};

const mutations = {
  SET_RECENT(state, activity) {
    state.recent = activity;
  },
  SET_FOLLOWS(state, activity) {
    state.follows = activity;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
