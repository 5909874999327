<template>
  <article
    class="relative group rounded-xl shadow-lg bg-white  text-center flex flex-grow h-full"
  >
    <router-link
      class="list-link p-2 md:p-4 flex flex-col justify-between  flex-grow "
      draggable="false"
      :to="{
        name: 'list',
        params: {
          username: list.username,
          listSlug: list.slug,
        },
      }"
    >
      <div
        v-if="list.gifts"
        class="grid gap-4  items-stretch text-xs sm:text-base"
        :class="giftsThumbnailsGridStyles(list.gifts.slice(0, 3), true)"
      >
        <img
          :src="gift.data.image"
          v-for="gift in list.gifts.slice(0, list.gifts.length === 4 ? 4 : 3)"
          :key="gift.id"
          :alt="gift.name"
          class="object-scale-down block w-full max-w-full"
          width="100%"
          style="max-height: 7.5em"
        />

        <div
          class="grid gap-1 "
          v-if="list.gifts.length > 4"
          :class="giftsThumbnailsGridStyles(list.gifts.slice(3, 7), true)"
        >
          <img
            :src="gift.data.image"
            v-for="gift in list.gifts.slice(3, 7)"
            :key="gift.id"
            :alt="gift.name"
            class="object-cover block w-full max-w-full"
          />
        </div>
        <div
          v-if="list.gifts.length == 0"
          class="w-full flex justify-center items-center bg-gray-100 flex-grow "
          style="min-height: 10rem;"
        >
          <div class="text-gray-400 font-light">empty</div>
        </div>
      </div>
      <div @click.prevent.stop v-if="canEdit" draggable="false">
        <textarea
          ref="listNameTextarea"
          draggable="false"
          class="label flex-shrink resize-none w-full overflow-hidden "
          v-model="name"
          @input="resizeTextarea"
          @blur="updateListName"
          @focus="cancelUpdateListName"
        >
        </textarea>
      </div>
      <div class="label flex-shrink" v-else>
        {{ list.name }}
      </div>
      <div
        v-if="list.setting_private"
        class="absolute top-0 left-0 mt-4 ml-4 bg-grey-200 text-green-600 font-semibold text-xs flex items-center rounded-xl bg-white bg-opacity-75 border-green-600 border px-2 py-1"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          width="15"
          height="15"
          style="width: 15px;height: 15px;"
        >
          <path
            fill-rule="evenodd"
            d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
            clip-rule="evenodd"
          />
          <path
            d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
          />
        </svg>
        <span class="ml-1">Private</span>
      </div>
    </router-link>
    <button
      v-if="canEdit"
      @click.stop="removeList"
      class="absolute top-0 right-0 opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 transition-opacity duration-150 delay-150 border-green-600 text-green-600  bg-yellow-200 w-8 h-8 -mt-2 -mr-2 md:-mt-4 md:-mr-4 rounded-full shadow-lg"
    >
      &#10006;
    </button>
  </article>
</template>

<script>
import { ref, watch, onMounted, onUpdated } from "vue";
import { useStore } from "vuex";
import slugify from "slugify";
export default {
  props: ["list", "canEdit"],
  setup(props, { emit }) {
    const name = ref(props.list.name);
    const listNameTextarea = ref(null);
    const store = useStore();

    const giftsThumbnailsGridStyles = (gifts, autorows = false) => {
      switch (gifts.length) {
        case 0:
        case 1:
          return "grid-cols-1 grid-rows-1";
        case 2:
          return "grid-cols-2 grid-rows-1";
        default:
          if (autorows) {
            return "grid-cols-2 grid-rows-max";
          }
          return "grid-cols-2 grid-rows-2";
      }
    };

    const removeList = () => {
      emit("remove-list", props.list.id);
    };

    const resizeTextarea = (e) => {
      if (e === null) return;
      const textarea = e.target || e;
      textarea.style.height = 0;
      textarea.style.height = textarea.scrollHeight + "px";
    };

    watch(listNameTextarea, () => {
      resizeTextarea(listNameTextarea.value);
    });

    onUpdated(() => {
      resizeTextarea(listNameTextarea.value);
    });

    onMounted(() => {
      resizeTextarea(listNameTextarea.value);
    });

    let updateListNameTimeout = null;
    const cancelUpdateListName = () => {
      clearTimeout(updateListNameTimeout);
    };

    const updateListName = async () => {
      updateListNameTimeout = setTimeout(async () => {
        if (name.value !== props.list.name) {
          const slug = slugify(name.value, { lower: true });
          const updatedList = await store.dispatch("lists/updateList", {
            id: props.list.id,
            updates: { name: name.value, slug },
          });
          name.value = updatedList.name;
        }
      }, 500);
    };

    return {
      giftsThumbnailsGridStyles,
      removeList,
      name,
      resizeTextarea,
      listNameTextarea,
      updateListName,
      cancelUpdateListName,
    };
  },
};
</script>

<style lang="postcss" scoped>
.label {
  @apply text-center font-bold mt-4 rounded-xl border  p-2;
  background-color: rgba(#ffffff, 0.8);
  border-color: var(--dark);
}
</style>
