<template>
  <base-card class="max-w-screen-sm my-8">
    <h1
      class="text-3xl font-semibold text-green-600  font-logo border-b-2 pb-2 border-green-600 mb-4 capitalize flex items-center"
    >
      Edit Your Profile
    </h1>

    <form
      @submit.prevent="handleUpdateProfile"
      class="flex flex-col  relative max-w-screen-sm mx-auto"
    >
      <div class="md:flex md:space-x-2 ">
        <div class="field flex-1">
          <label for="firstname">First name</label>
          <input type="text" id="firstname" v-model="firstname" />
        </div>

        <div class="field flex-1">
          <label for="lastname">Last name</label>
          <input type="text" id="lastname" v-model="lastname" />
        </div>
      </div>

      <div class="field"></div>

      <div class="md:flex md:space-x-2 ">
        <div class="field flex-1">
          <label for="email">Email</label>
          <input type="email" id="email" v-model="email" />
        </div>

        <div class="field flex-1">
          <label for="user">Username</label>
          <input type="text" id="username" v-model="username" />
          <div class="text-xs mt-1">
            Note: Changing this will change your list urls
          </div>
        </div>
      </div>

      <p v-if="error" class="text-red-500 text-sm">
        <strong>Error:</strong> {{ error }}
      </p>
      <p v-if="success" class="text-green-600 text-sm">
        <strong>Sucess:</strong> {{ success }}
      </p>
      <base-button type="submit">Update Profile</base-button>
    </form>
  </base-card>
</template>

<script>
import { ref, watch } from "vue";
import {
  currentUser,
  usernameExists,
  updateProfile,
} from "@/modules/auth/services/AuthService";
export default {
  setup() {
    const error = ref(null);
    const success = ref(null);

    const firstname = ref(currentUser.value ? currentUser.value.firstname : "");
    const lastname = ref(currentUser.value ? currentUser.value.lastname : "");
    const username = ref(currentUser.value ? currentUser.value.username : "");
    const email = ref(currentUser.value ? currentUser.value.email : "");

    watch(currentUser, () => {
      firstname.value = currentUser.value.firstname;
      lastname.value = currentUser.value.lastname;
      username.value = currentUser.value.username;
      email.value = currentUser.value.email;
    });

    const validateProfile = async () => {
      error.value = "";
      if (
        !email.value ||
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email.value
        ) === false
      ) {
        error.value = "Email Address Invalid";
        return false;
      }

      if (firstname.value.length === 0) {
        error.value = "Enter a first name";
        return false;
      }

      if (lastname.value.length === 0) {
        error.value = "Enter a last name";
        return false;
      }

      if (username.value.length === 0) {
        error.value = "Enter a username";
        return false;
      }

      try {
        if (
          username.value !== currentUser.value.username &&
          (await usernameExists(username.value))
        ) {
          error.value = "Username already exists";
          return false;
        }
      } catch (err) {
        error.value = err;
        return false;
      }

      return true;
    };

    const handleUpdateProfile = async () => {
      success.value = "";
      if (!(await validateProfile())) return false;

      try {
        await updateProfile(
          email.value,
          username.value,
          firstname.value,
          lastname.value
        );
        success.value = "Profile Updated!";
      } catch (err) {
        error.value = err.message;
      }
    };

    return {
      success,
      error,
      firstname,
      lastname,
      email,
      username,
      handleUpdateProfile,
    };
  },
};
</script>
