<template>
  <div class="base-card rounded-xl p-8 mx-2 sm:mx-auto shadow-md">
    <slot></slot>
  </div>
</template>

<style lang="postcss" scoped>
.field {
  @apply flex flex-col py-2;
  label {
    @apply mb-1 text-green-600;
  }
  input {
    @apply p-2;
  }
}
</style>
