<template>
  <teleport to="#modals">
    <transition name="modal-animation">
      <div
        class="shade fixed inset-0 bg-black bg-opacity-40 flex items-center justify-center z-50"
        v-if="isOpen"
        @click.self.prevent="close"
      >
        <div
          class="modal rounded-xl bg-green-50 p-8 mx-2 sm:mx-auto shadow-2xl inline-block relative"
        >
          <slot></slot>
          <button
            @click.prevent="close"
            class="absolute top-0 right-0 pointer-events-auto 
                  border-green-600 text-green-600  bg-yellow-200 w-8 h-8 
                  -mt-2 -mr-2 md:-mt-3 md:-mr-3 rounded-full shadow-md"
          >
            &#10006;
          </button>
        </div>
      </div>
    </transition>
  </teleport>
</template>

<script>
export default {
  emits: ["close"],
  props: { isOpen: { type: Boolean, default: false, required: false } },
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };

    return {
      close,
    };
  },
};
</script>

<style lang="postcss" scoped>
.field {
  @apply flex flex-col py-2;
  label {
    @apply mb-1 text-green-600;
  }
  input {
    @apply p-2;
  }
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: all 0.3s ease;
  &.shade,
  .modal {
    transition: all 0.3s ease;
  }
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  &.shade {
    --tw-bg-opacity: 0;
  }
  .modal {
    transform: translateY(-100px);
    opacity: 0;
  }
}
</style>
