/**
 *
 * @param  {name: string, slug: string} list
 * @callback lookupFunction
 * @params {string} username
 * @params {string} slug
 */
export default function(list, lookupFunction) {
  const lookupList = lookupFunction(list.username, list.slug);
  if (lookupList && (lookupList.id !== list.id || list.id === undefined)) {
    const slugRegex = /(.+-)(\d{1,2})$/;
    const nameRegex = /(.+\s)(\d{1,2})$/;
    const incrementor = (match, base, number) => {
      return base + (Number(number) + 1).toString();
    };

    if (!list.slug.match(slugRegex)) {
      list.name += " 2";
      list.slug += "-2";
    }

    while (lookupFunction(list.username, list.slug)) {
      list.slug = list.slug.replace(slugRegex, incrementor);
      list.name = list.name.replace(nameRegex, incrementor);
    }
  }

  return list;
}
