<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import gravatar from "gravatar";
import {
  currentUser,
  following,
  follow,
  unfollow,
} from "@/modules/auth/services/AuthService";

export default {
  props: {
    username: String,
  },

  setup(props) {
    const store = useStore();

    const user = computed(() =>
      store.getters["users/userByUsername"](props.username)
    );

    if (!user.value) {
      store.dispatch("users/getUser", props.username);
    }

    const followUser = async () => {
      await follow(props.username);
    };

    const unfollowUser = async () => {
      await unfollow(props.username);
    };

    const isFollowing = computed(() => {
      return following.value.find((user) => user.username === props.username);
    });

    const isHoveringFollow = ref(false);

    return {
      gravatar,
      user,
      currentUser,
      followUser,
      unfollowUser,
      isFollowing,
      isHoveringFollow,
    };
  },
};
</script>

<template>
  <div v-if="user" class="user-card">
    <router-link
      :to="{ name: 'lists', params: { username: user.username } }"
      class="flex justify-between flex-grow"
    >
      <div class="flex justify-between w-full max-w-full">
        <img
          class="h-12 w-12 rounded-full mr-4 border border-black bg-white  flex-shrink-0"
          :src="
            gravatar.url(user.email, {
              protocol: 'https',
              s: '100',
              d: 'robohash',
            })
          "
          alt=""
        />

        <div class="flex flex-col justify-center  truncate mr-2 flex-grow">
          <h3 class="text-lg font-medium leading-none truncate">
            {{ user.firstname }} {{ user.lastname }}
          </h3>
          <h4 class="text-yellow-400 font-medium   truncate">
            @{{ user.username }}
          </h4>
        </div>
      </div>
    </router-link>

    <div
      v-if="currentUser && currentUser.username !== username"
      class="flex-shrink"
    >
      <div v-if="!isFollowing">
        <base-button
          class="text-xs self-center mt-0 py-1 bg-yellow-200 text-green-600 hover:bg-yellow-300 "
          @click="followUser"
        >
          Follow
        </base-button>
      </div>
      <div
        v-else-if="isFollowing"
        class="self-start flex-shrink"
        @mouseenter="isHoveringFollow = true"
        @mouseleave="isHoveringFollow = false"
      >
        <base-button
          class="text-xs self-center mt-0 py-1 text-green-600 bg-green-50 hover:bg-green-100 relative"
          @click="unfollowUser"
        >
          <span v-show="isHoveringFollow" class="absolute left-0 right-0 "
            >Unfollow</span
          >
          <span :class="isHoveringFollow ? 'invisible' : ''">Following</span>
        </base-button>
      </div>
    </div>
  </div>
</template>

<style lang="postcss">
.user-card {
  @apply py-2 md:py-4 flex  justify-between w-full text-base font-sans;
}
</style>
