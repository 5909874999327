<template>
  <div
    class="giftbox relative z-10 group"
    :style="wrappingPaper.styles"
    :class="[
      wrappingPaper.classes,
      claimed && canClaim ? 'claimed' : '',
      isExpanded
        ? ['col-span-2', 'row-span-2', 'justify-self-start', 'self-start']
        : '',
    ]"
  >
    <a class="" draggable="false" target="_blank" :href="gift.data.url">
      <div
        class="contents relative"
        style=" min-height: 10rem; max-height: 18rem;"
      >
        <div
          class="giftbox-image-container bg-white bg-opacity-90 group-hover:bg-opacity-100  rounded-lg flex-grow flex justify-center items-stretch group-hover:items-center p-0 group-hover:p-1 transition-all duration-400 overflow-hidden"
          style="flex-basis: 14rem; "
        >
          <img
            v-show="gift.data.image"
            :src="gift.data.image"
            alt=""
            class="giftbox-image max-w-full group-hover:w-auto rounded-md max-h-full object-cover block flex-grow group-hover:flex-grow-0 flex-shrink-0 group-hover:max-h-full transition-all duration-400"
            style="mix-blend-mode:multiply"
          />
        </div>
        <div
          v-if="canEdit"
          @click.prevent.stop
          draggable="false"
          class="flex-shrink"
        >
          <textarea
            ref="giftNameTextarea"
            draggable="false"
            :style="wrappingPaper.styles"
            class="label flex-shrink resize-none w-full overflow-hidden "
            v-model="name"
            @input="resizeTextarea"
            @blur="updateGiftName"
            @focus="cancelUpdateGiftName"
          >
          </textarea>
        </div>
        <div
          v-else
          :style="wrappingPaper.styles"
          class="label flex-grow-0 flex-shrink"
        >
          {{ gift.data.name }}
        </div>
        <button
          v-if="canEdit"
          @click.prevent="removeGift"
          class="absolute top-0 right-0 opacity-0 pointer-events-none group-hover:pointer-events-auto group-hover:opacity-100 transition-opacity duration-150 delay-150 border-green-600 text-green-600  bg-yellow-200 w-8 h-8 -mt-4 -mr-4 md:-mt-7 md:-mr-7 rounded-full shadow-lg"
        >
          &#10006;
        </button>
      </div>
    </a>
    <div
      v-if="canClaim"
      class="giftbox__buttons flex justify-end flex-grow flex-1"
      :class="wrappingPaper.buttonClasses"
    >
      <button @click="claimGift" v-if="!claimed">
        Claim
      </button>
      <button @click="unclaimGift" v-else :disabled="!canUnclaim">
        Unclaim
      </button>
    </div>
    <div class="ribbon" v-if="claimed && canClaim"><span>Claimed</span></div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onUpdated, computed } from "vue";
import { useStore } from "vuex";
import { currentUser } from "@/modules/auth/services/AuthService";
import useWrappingPaper from "./WrappingPaper";

export default {
  emits: ["remove-gift"],
  props: {
    gift: Object,
    canEdit: Boolean,
    canClaim: Boolean,
  },
  setup(props, { emit }) {
    const name = ref(props.gift.data.name);
    const giftNameTextarea = ref(null);
    const store = useStore();

    const wrappingPaper = props.gift.data.wrapping
      ? useWrappingPaper(
          props.gift.data.wrapping.type,
          props.gift.data.wrapping.dark,
          props.gift.data.wrapping.light
        )
      : useWrappingPaper();

    const isExpanded = ref(false);
    const toggleExpaned = () => {
      isExpanded.value = !isExpanded.value;
    };

    const removeGift = () => {
      emit("remove-gift", props.gift.id);
    };

    const resizeTextarea = (e) => {
      if (e === null) return;
      const textarea = e.target || e;
      textarea.style.height = 0;
      textarea.style.height = textarea.scrollHeight + 1 + "px";
    };

    watch(giftNameTextarea, () => {
      resizeTextarea(giftNameTextarea.value);
    });

    onUpdated(() => {
      resizeTextarea(giftNameTextarea.value);
    });

    onMounted(() => {
      resizeTextarea(giftNameTextarea.value);
    });

    let updateGiftNameTimeout = null;

    const cancelUpdateGiftName = () => {
      clearTimeout(updateGiftNameTimeout);
    };

    const updateGiftName = () => {
      updateGiftNameTimeout = setTimeout(async () => {
        if (name.value !== props.gift.data.name) {
          const updatedGift = await store.dispatch("lists/updateGift", {
            id: props.gift.id,
            updates: { data: { ...props.gift.data, name: name.value } },
          });
          name.value = updatedGift.data.name;
        }
      }, 500);
    };

    const claimGift = async () => {
      await store.dispatch("lists/claimGift", { gift: props.gift });
    };

    const unclaimGift = async () => {
      await store.dispatch("lists/unclaimGift", { gift: props.gift });
    };

    const claimed = computed(() => {
      return props.gift.claimed && props.gift.claimed.length > 0;
    });

    const canUnclaim = computed(() =>
      props.gift.claimed.find((x) => x.id === currentUser.value.id)
    );

    return {
      name,
      wrappingPaper,
      isExpanded,
      toggleExpaned,
      removeGift,
      resizeTextarea,
      cancelUpdateGiftName,
      updateGiftName,
      giftNameTextarea,
      claimGift,
      unclaimGift,
      claimed,
      canUnclaim,
    };
  },
};
</script>

<style lang="postcss">
.giftbox {
  @apply p-2 md:p-4 rounded-xl shadow-lg;
  .contents {
    @apply h-full flex flex-col justify-end;
  }

  &.claimed {
    .giftbox-image {
      filter: blur(2px);
    }
    .giftbox-image-container {
      opacity: 0.7;
    }
    &:hover {
      .giftbox-image {
        filter: blur(0);
      }
      .giftbox-image-container {
        opacity: 1;
      }
    }
  }

  .label {
    @apply text-center font-bold mt-4 rounded-xl border-2 leading-none p-2;
    background-color: rgba(#ffffff, 0.8);
    border-color: var(--dark);
  }
}
.giftbox__buttons {
  @apply -mx-2 -mb-2 mt-2 md:mt-4 md:-mx-4 md:-mb-4 rounded-b-xl p-2 md:px-4;

  button {
    border-color: var(--light);
    color: black;
    background-color: rgba(#ffffff, 0.8);
    border-color: var(--dark);
    @apply font-bold border-2 py-1 px-2
      rounded-lg hover:text-green-600 hover:bg-yellow-100 hover:border-yellow-300 transition-colors duration-100;
    &[disabled] {
      visibility: hidden;
    }
  }
}
.ribbon {
  position: absolute;
  left: -5px;
  top: -5px;
  z-index: 1;
  overflow: hidden;
  width: 75px;
  height: 75px;
  text-align: right;
}
.ribbon span {
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  text-shadow: 0 0 1px black;
  text-transform: uppercase;
  text-align: center;
  line-height: 20px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  width: 100px;
  display: block;
  background: var(--dark);
  background: linear-gradient(var(--dark) 50%, var(--dark) 100%);
  box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
  position: absolute;
  top: 19px;
  left: -21px;
}
.ribbon span::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid var(--dark);
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--dark);
}
.ribbon span::after {
  content: "";
  position: absolute;
  right: 0px;
  top: 100%;
  z-index: -1;
  border-left: 3px solid transparent;
  border-right: 3px solid var(--dark);
  border-bottom: 3px solid transparent;
  border-top: 3px solid var(--dark);
}
</style>

<style lang="postcss" scoped></style>
