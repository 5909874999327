<script>
import ActivityLog from "../modules/activity/components/ActivityLog.vue";
import ShowDashboard from "@/modules/dashboard/pages/ShowDashboard.vue";
import { isLoggedIn } from "@/modules/auth/services/AuthService";
export default {
  components: {
    ActivityLog,
    ShowDashboard,
  },
  setup() {
    return {
      isLoggedIn,
    };
  },
};
</script>

<template>
  <ShowDashboard v-if="isLoggedIn" />
  <base-content v-else>
    <template #title>
      <div class="text-center ">
        <h1 class="text-3xl text-green-600">
          Welcome to <strong class="font-logo">gift.town</strong>
        </h1>
        <p class="mt-2 text-xl">A place to create, view and shop gift lists</p>
      </div>
    </template>
    <section class="text-center text-lg space-y-5 p-5">
      <h2 class="text-3xl text-green-600 font-semibold">Check it out</h2>
      <p>
        Take a look at my lists
        <router-link to="/lists/steve">
          <base-button class="text-sm">View Steve's Lists</base-button>
        </router-link>
      </p>
      <p>
        <router-link to="/signup">
          <base-button class="text-sm">Signup</base-button>
        </router-link>
        or
        <router-link to="/login">
          <base-button class="text-sm">Login</base-button>
        </router-link>
        to create your own!
      </p>
    </section>
    <section class="text-center text-lg space-y-5 p-5">
      <h2 class="text-3xl text-green-600 font-semibold">Recent Activity</h2>
      <ActivityLog class="text-left" />
    </section>
  </base-content>
</template>
