<script>
import { ref } from "vue";
import { useStore } from "vuex";
import ListBox from "../components/ListBox.vue";
import NewList from "../components/NewList.vue";

export default {
  props: {
    username: {
      type: String,
      required: false,
    },
    canEdit: {
      type: Boolean,
      required: false,
      default: false,
    },
    lists: {
      type: Array,
      required: true,
    },
  },
  components: {
    ListBox,
    NewList,
  },
  setup() {
    const store = useStore();
    const removeList = async (id) => {
      await store.dispatch("lists/removeList", id);
    };

    const showNewList = ref(false);

    return {
      removeList,
      showNewList,
    };
  },
};
</script>

<template>
  <ul
    v-if="lists && lists.length > 0"
    class="relative max-w-screen-xl mx-auto"
    :class="$attrs.class"
  >
    <li v-for="list in lists" :key="list.id">
      <list-box
        :list="list"
        :canEdit="canEdit"
        @remove-list="removeList"
      ></list-box>
    </li>
  </ul>
  <p v-else-if="canEdit" class="font-semibold text-lg text-center py-20">
    You don't have any lists yet.
    <base-button class="text-xs ml-4" @click="showNewList = true">
      Create New List
    </base-button>
    <NewList :is-open="showNewList" @close="showNewList = false" />
  </p>
  <p v-else-if="username" class="font-semibold text-lg text-center py-20">
    @{{ username }} doesn't have any lists yet!
  </p>
</template>

<style lang="postcss"></style>
