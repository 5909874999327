import { useSupabase } from "@/services/supabase";

const state = () => {
  return {
    users: [],
  };
};

const mutations = {
  SET_USERS(state, users) {
    state.users = users;
  },
  ADD_USER(state, user) {
    state.users.push(user);
  },
};

const actions = {
  async getUsers({ commit }) {
    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("users")
      .select("firstname, lastname, username, email")
      .order("firstname,lastname");

    if (error) {
      throw new Error(error);
    }

    commit("SET_USERS", data);
  },

  async getUser({ commit }, username) {
    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("users")
      .select("firstname, lastname, username, email")
      .order("firstname,lastname")
      .eq("username", username)
      .single();

    if (error) {
      throw new Error(error);
    }

    commit("ADD_USER", data);
  },
};

const getters = {
  users(state) {
    return state.users;
  },
  user(state) {
    return (id) => {
      return state.users.find((user) => user.id === id);
    };
  },
  userByUsername(state) {
    return (username) => {
      return state.users.find((user) => user.username === username);
    };
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
