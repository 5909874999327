<template>
  <base-content>
    <template #title>
      <UserCard :username="username" class="-mt-4" />
      <h1 class=" relative mt-0" v-if="list">
        <div class=" flex items-center">
          <span>
            {{ list.name }}
          </span>
          <base-button @click="showEditList = true" class="text-xs mx-4">
            Edit List
          </base-button>
          <div
            v-if="list.setting_private"
            class="bg-grey-200 text-green-600 font-semibold text-xs flex items-center rounded-xl bg-white bg-opacity-75 border-green-600 border px-2 py-1"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              width="15"
              height="15"
              style="width: 15px;height: 15px;"
            >
              <path
                fill-rule="evenodd"
                d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z"
                clip-rule="evenodd"
              />
              <path
                d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z"
              />
            </svg>
            <span class="ml-1">Private</span>
          </div>
        </div>
      </h1>

      <gift-add-form
        v-if="canEdit"
        @submit="addGift"
        class="flex flex-col row-span-1 col-span-2 md:col-span-3 lg:col-span-4"
      ></gift-add-form>
    </template>

    <div>
      <section
        v-if="list"
        ref="giftListGrid"
        class="grid gap-4 md:gap-6 md:grid-cols-2 auto-rows-min grid-flow-row-dense lg:grid-cols-3 xl:grid-cols-4 p-2 md:p-6 relative max-w-screen-xl mx-auto"
      >
        <gift-box
          v-for="gift in list.gifts"
          @remove-gift="removeGift"
          :canEdit="canEdit"
          :canClaim="canClaim"
          :key="gift.id"
          :gift="gift"
        ></gift-box>
      </section>
    </div>
  </base-content>

  <EditList
    :is-open="showEditList"
    @close="showEditList = false"
    @updated="listUpdated"
    :username="username"
    :listSlug="listSlug"
  />
</template>

<script>
import { currentUser } from "@/modules/auth/services/AuthService";
import GiftAddForm from "@/modules/gifts/components/GiftAddForm.vue";
import GiftBox from "@/modules/gifts/components/GiftBox.vue";
import UserCard from "@/modules/users/components/UserCard";
import { wrapGrid } from "animate-css-grid";
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import EditList from "../components/EditList.vue";

export default {
  props: ["username", "listSlug"],
  components: {
    GiftBox,
    GiftAddForm,
    UserCard,
    EditList,
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();
    const giftListGrid = ref(null);
    const showEditList = ref(false);

    const canEdit = computed(() => {
      return (
        currentUser.value &&
        list.value &&
        currentUser.value.id === list.value.uid
      );
    });

    const canClaim = computed(() => {
      return (
        currentUser.value &&
        list.value &&
        list.value.setting_claimable &&
        (currentUser.value.id !== list.value.uid ||
          list.value.setting_self_claimable === true)
      );
    });

    const addGift = async (payload) => {
      const gift = {
        name: payload.name,
        image: payload.image,
        url: payload.url,
        wrapping: {
          type: payload.wrapType,
          dark: payload.wrapDark,
          light: payload.wrapLight,
        },
      };
      await store.dispatch("lists/addGift", {
        username: props.username,
        slug: props.listSlug,
        gift,
      });
    };

    const removeGift = async (id) => {
      await store.dispatch("lists/removeGift", id);
    };

    watch(giftListGrid, () => {
      if (giftListGrid.value) {
        wrapGrid(giftListGrid.value, {
          easing: "backOut",
          stagger: 10,
          duration: 400,
        });
      }
    });

    store.dispatch("lists/getList", {
      username: props.username,
      slug: props.listSlug,
    });

    const list = computed(() =>
      store.getters["lists/list"](props.username, props.listSlug)
    );

    const listUpdated = (updatedList) => {
      showEditList.value = false;

      if (updatedList.slug !== props.listSlug) {
        router.replace({
          name: "list",
          params: {
            username: props.username,
            listSlug: updatedList.slug,
          },
        });
      }
    };

    return {
      addGift,
      removeGift,
      giftListGrid,
      canEdit,
      canClaim,
      list,
      showEditList,
      listUpdated,
    };
  },
};
</script>

<style lang="postcss" scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100px);
  opacity: 0;
}
</style>
