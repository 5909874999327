<template>
  <base-card class="max-w-screen-sm my-8">
    <h1
      class="text-3xl font-semibold text-green-600  font-logo border-b-2 pb-2 border-green-600 mb-4 capitalize flex items-center"
    >
      Change Your Password
    </h1>
    <form
      @submit.prevent="handleChangePassword"
      class="flex flex-col  relative max-w-screen-sm mx-auto"
    >
      <div class="md:flex md:space-x-2 ">
        <div class="field flex-1">
          <label for="password">New Password</label>
          <input type="password" id="password" v-model="password" />
        </div>

        <div class="field flex-1">
          <label for="passwordConfirm">Confirm New Password</label>
          <input
            type="password"
            id="passwordConfirm"
            v-model="passwordConfirm"
          />
        </div>
      </div>
      <p v-if="error" class="text-red-500 text-sm">
        <strong>Error:</strong> {{ error }}
      </p>
      <p v-if="success" class="text-green-600 text-sm">
        <strong>Sucess:</strong> {{ success }}
      </p>
      <base-button type="submit">Change Password</base-button>
    </form>
  </base-card>
</template>

<script>
import { ref } from "vue";
import { changePassword } from "@/modules/auth/services/AuthService";
export default {
  setup() {
    const error = ref(null);
    const success = ref(null);

    const password = ref("");
    const passwordConfirm = ref("");

    const validatePassword = async () => {
      error.value = "";
      if (password.value.length < 8) {
        error.value = "Enter a strong password";
        return false;
      }

      if (password.value !== passwordConfirm.value) {
        error.value = "Passwords don't match";
        return false;
      }

      return true;
    };

    const handleChangePassword = async () => {
      success.value = "";
      if (!validatePassword()) return false;

      try {
        await changePassword(password.value);
        success.value = "Password Changed!";
      } catch (err) {
        error.value = err.message;
      }
    };

    return {
      success,
      error,
      password,
      passwordConfirm,
      handleChangePassword,
    };
  },
};
</script>
