<template>
  <base-modal @close="emitClose()" :is-open="isOpen">
    <h1>New List</h1>

    <form
      @submit.prevent="createList"
      class="flex flex-col  relative max-w-screen-sm mx-auto"
    >
      <base-field type="text" label="Name" v-model="name" id="name" />

      <fieldset>
        <legend>Settings</legend>

        <p class="text-sm text-gray-900 font-normal mb-6">
          You can change these later:
        </p>

        <base-toggle
          v-model="settings.self_claimable"
          :invert="true"
          label="For Me"
          description=" (Hides what has been claimed from you)"
          class="my-4"
        />

        <base-toggle
          v-model="settings.claimable"
          label="Claiming"
          description=" (Others can claim items off this list)"
          class="my-4"
        />

        <base-toggle
          v-model="settings.private"
          label="Private"
          description=" (Other's can't see this list)"
          class="my-4"
        />
      </fieldset>

      <p v-if="error" class="text-red-500 text-sm">
        <strong>Error:</strong> {{ error }}
      </p>
      <base-button type="submit">Create List</base-button>
    </form>
  </base-modal>
</template>

<script>
import { ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import slugify from "slugify";
import { currentUser } from "@/modules/auth/services/AuthService";

export default {
  emits: ["close"],
  props: { isOpen: Boolean },
  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();

    const name = ref("");
    const settings = reactive({
      self_claimable: false,
      claimable: true,
      private: false,
    });
    const error = ref("");

    const createList = async () => {
      try {
        if (name.value === "") {
          error.value = "Please enter a list name";
          return false;
        }

        const slug = slugify(name.value, { lower: true });

        const listData = {
          name: name.value,
          slug: slug,
          uid: currentUser.value.id,
          username: currentUser.value.username,
          setting_self_claimable: settings.self_claimable,
          setting_claimable: settings.claimable,
          setting_private: settings.private,
        };

        const newList = await store.dispatch("lists/createList", listData);
        router.push({
          name: "list",
          params: { username: newList.username, listSlug: newList.slug },
        });
      } catch (err) {
        error.value = err.message;
      }
    };

    const emitClose = () => {
      emit("close");
    };

    return {
      name,
      settings,
      error,
      createList,
      emitClose,
    };
  },
};
</script>

<style lang="postcss" scoped>
fieldset {
  @apply border border-green-600 px-6 py-4  my-4;
  legend {
    @apply text-green-600 font-medium px-2 -ml-2;
  }
}
</style>
