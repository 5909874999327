import { useSupabase } from "@/services/supabase";
import uniqueListName from "../helpers/uniqueListName";
import { currentUser } from "@/modules/auth/services/AuthService";

const state = () => {
  return {
    lists: [],
  };
};

const getters = {
  lists(state) {
    return (username) => {
      return state.lists.filter((list) => list.username === username);
    };
  },

  list(state) {
    return (username, slug) => {
      return state.lists.find(
        (list) => list.username === username && list.slug === slug
      );
    };
  },

  listById(state) {
    return (id) => {
      return state.lists.find((list) => list.id === id);
    };
  },
};

const validateList = (list) => {
  // Require list to have a user, including username for store population
  if (list.username === null || list.uid === null) {
    throw new Error("List requires valid user");
  }

  // Require list to have a slug
  if (list.slug === null) {
    throw new Error("List requires valid slug");
  }

  // Require list to have a name
  if (list.name === null) {
    throw new Error("List requires valid name");
  }

  return true;
};
const actions = {
  async createList({ commit, getters }, list) {
    if (!validateList(list)) return false;

    list = uniqueListName(list, getters.list);
    const { username: _, ...listWithoutUsername } = list;

    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("lists")
      .insert(listWithoutUsername)
      .single();

    if (error) {
      throw new Error(error);
    }

    // Add List ID if it doesn't already exists
    list.id = data.id;

    commit("ADD_LIST", list);

    return list;
  },

  async removeList({ commit }, id) {
    const supabase = await useSupabase();
    const { error } = await supabase
      .from("lists")
      .delete()
      .eq("id", id);
    if (error) {
      throw new Error(error);
    }
    commit("REMOVE_LIST", id);
  },

  async getLists({ commit }, { username }) {
    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("lists_by_username")
      .select("*,gifts!list_id(*)")
      .eq("username", username)
      .order("id", { ascending: false, foreignTable: "gifts" });

    if (error) {
      throw new Error(error);
    }

    commit("SET_LISTS", { username, lists: data });
  },

  async getList({ commit }, { username, slug }) {
    const supabase = await useSupabase();

    const select =
      "*,gifts!list_id(id, list_id, data,claimed:gifts_claimed(id:uid))";

    const { data, error } = await supabase
      .from("lists_by_username")
      .select(select)
      .eq("username", username)
      .eq("slug", slug)
      .order("id", { ascending: false, foreignTable: "gifts" })
      .single();

    if (error) {
      throw new Error(error);
    }

    commit("UPDATE_LIST", data);
  },

  async updateList({ commit, getters }, { id, updates }) {
    const supabase = await useSupabase();

    if (updates.name && updates.slug) {
      let list = getters.listById(id);
      list = { ...list, ...updates };
      const { name, slug } = uniqueListName(list, getters.list);
      updates.name = name;
      updates.slug = slug;
    }

    const { data, error } = await supabase
      .from("lists")
      .update(updates)
      .eq("id", id);

    if (error) {
      throw new Error(error);
    }

    commit("UPDATE_LIST", data[0]);
    return data[0];
  },

  async addGift({ commit, getters }, { username, slug, gift }) {
    const list = getters["list"](username, slug);
    const supabase = await useSupabase();
    const { data, error } = await supabase.from("gifts").insert({
      data: gift,
      list_id: list.id,
    });

    if (error) {
      throw new Error(error);
    }

    commit("ADD_GIFT", { listId: list.id, gift: data[0] });
  },

  async updateGift({ commit }, { id, updates }) {
    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("gifts")
      .update(updates)
      .eq("id", id)
      .single();

    if (error) {
      throw new Error(error);
    }

    commit("UPDATE_GIFT", data);
    return data;
  },

  async claimGift({ commit }, { gift, user }) {
    user = user ?? currentUser.value;

    const supabase = await useSupabase();
    const { error } = await supabase.from("gifts_claimed").insert({
      gift_id: gift.id,
      uid: user.id,
    });

    if (error) {
      throw new Error(error);
    }

    commit("CLAIM_GIFT", { gift, user });
  },

  async unclaimGift({ commit }, { gift, user }) {
    user = user ?? currentUser.value;

    const supabase = await useSupabase();
    const { error } = await supabase
      .from("gifts_claimed")
      .delete()
      .eq("gift_id", gift.id)
      .eq("uid", user.id);

    if (error) {
      throw new Error(error);
    }

    commit("UNCLAIM_GIFT", { gift, user });
  },

  async removeGift({ commit }, id) {
    const supabase = await useSupabase();
    const { data, error } = await supabase
      .from("gifts")
      .delete()
      .eq("id", id)
      .single();

    if (error) {
      throw new Error(error);
    }

    commit("REMOVE_GIFT", { listId: data.list_id, id });
  },
};

const mutations = {
  ADD_LIST(state, list) {
    state.lists.unshift(list);
  },

  UPDATE_LIST(state, updatedList) {
    let found = false;

    state.lists = state.lists.map((list) => {
      if (list.id === updatedList.id) {
        found = true;
        return { ...list, ...updatedList };
      }
      return list;
    });

    if (!found) {
      state.lists.unshift(updatedList);
    }
  },

  REMOVE_LIST(state, id) {
    state.lists = state.lists.filter((list) => list.id !== id);
  },

  SET_LISTS(state, { username, lists }) {
    const unalteredLists = state.lists.filter(
      (list) => list.username !== username
    );
    state.lists = unalteredLists.concat(lists);
  },

  ADD_GIFT(state, { listId, gift }) {
    state.lists.find((list) => list.id === listId).gifts.unshift(gift);
  },

  UPDATE_GIFT(state, updatedGift) {
    const list = state.lists.find((list) => list.id === updatedGift.list_id);

    list.gifts = list.gifts.map((gift) => {
      if (gift.id === updatedGift.id) {
        gift.data = { ...gift.data, ...updatedGift.data };
      }
      return gift;
    });
  },

  CLAIM_GIFT(state, { gift, user }) {
    const list = state.lists.find((list) => list.id === gift.list_id);
    list.gifts = list.gifts.map((listGift) => {
      if (listGift.id === gift.id) {
        listGift.claimed = listGift.claimed ?? [];
        listGift.claimed.push({ id: user.id });
      }
      return listGift;
    });
  },

  UNCLAIM_GIFT(state, { gift, user }) {
    const list = state.lists.find((list) => list.id === gift.list_id);
    list.gifts = list.gifts.map((listGift) => {
      if (listGift.id === gift.id) {
        listGift.claimed = listGift.claimed ?? [];
        listGift.claimed = listGift.claimed.filter(
          (claimedUser) => claimedUser.id !== user.id
        );
      }
      return listGift;
    });
  },

  REMOVE_GIFT(state, { listId, id }) {
    const gifts = state.lists.find((list) => list.id === listId).gifts;
    const updatedGifts = gifts.filter((gift) => gift.id !== id);
    state.lists.find((list) => list.id === listId).gifts = updatedGifts;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
