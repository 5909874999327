<script>
export default function useWrappingPaper(
  type = "cross",
  darkColor = "#cccccc",
  lightColor = "#eeeeee"
) {
  return {
    classes: `wrapping-paper wrapping-paper--${type}`,
    styles: {
      "--dark": darkColor,
      "--light": lightColor,
    },
    buttonClasses: `wrapping-paper-buttons`,
  };
}

import * as Vibrant from "node-vibrant";
// import {reactive} from "vue";

export async function generateWrappingPaperFromImage(imageUrl) {
  const wrappingPaperTypes = ["cross", "moon", "zigzag"];
  const wrappingPaper = {
    wrapDark: "#cccccc",
    wrapLight: "#eeeeee",
    wrapType:
      wrappingPaperTypes[Math.floor(Math.random() * wrappingPaperTypes.length)],
  };

  if (imageUrl) {
    if (imageUrl.match(/^\/\/.+/)) {
      imageUrl = "http:" + imageUrl;
    }

    if (imageUrl.match("/^http.+/")) {
      imageUrl = "https://z.gift.town/?imageUrl=" + encodeURI(imageUrl);
    }

    await Vibrant.from(imageUrl)
      .getPalette()
      .then(function(palette) {
        const paletteOptions = [
          "DarkMuted",
          "DarkVibrant",
          "LightMuted",
          "LightVibrant",
          "Muted",
          "Vibrant",
        ];

        const random1 = Math.floor(Math.random() * paletteOptions.length);
        const palette1 = paletteOptions.splice(random1, 1);

        const random2 = Math.floor(Math.random() * paletteOptions.length);
        const palette2 = paletteOptions[random2];

        wrappingPaper.wrapDark = palette[palette1].getHex();
        wrappingPaper.wrapLight = palette[palette2].getHex();
      });
  }

  return wrappingPaper;
}
</script>

<style lang="postcss">
.wrapping-paper {
  &.wrapping-paper--zigzag {
    background-color: var(--dark);

    background-image: linear-gradient(135deg, var(--dark) 25%, transparent 25%),
      linear-gradient(225deg, var(--dark) 25%, transparent 25%),
      linear-gradient(45deg, var(--dark) 25%, transparent 25%),
      linear-gradient(315deg, var(--dark) 25%, var(--light) 25%);
    background-position: 11px 0, 11px 0, 0 0, 0 0;
    background-size: 22px 22px;
    background-repeat: repeat;
  }
  &.wrapping-paper--cross {
    background-color: var(--light);

    background: radial-gradient(
        circle,
        transparent 20%,
        var(--light) 20%,
        var(--light) 80%,
        transparent 80%,
        transparent
      ),
      radial-gradient(
          circle,
          transparent 20%,
          var(--light) 20%,
          var(--light) 80%,
          transparent 80%,
          transparent
        )
        20px 20px,
      linear-gradient(var(--dark) 1.6px, transparent 1.6px) 0 -0.8px,
      linear-gradient(90deg, var(--dark) 1.6px, var(--light) 1.6px) -0.8px 0;
    background-size: 40px 40px, 40px 40px, 20px 20px, 20px 20px;
  }
  &.wrapping-paper--moon {
    background-color: var(--light);

    background-image: radial-gradient(
      ellipse farthest-corner at 30px 30px,
      var(--dark),
      var(--dark) 50%,
      var(--light) 50%
    );
    background-size: 30px 30px;
  }

  .wrapping-paper-buttons {
    background: linear-gradient(270deg, var(--dark), var(--light));
  }
}
</style>
