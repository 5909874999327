<script>
import { currentUser } from "@/modules/auth/services/AuthService";
import NewList from "../components/NewList.vue";
import UserCard from "@/modules/users/components/UserCard.vue";
import { computed, ref } from "vue";
import ShowLists from "../components/ShowLists.vue";
import { listsForUsername } from "../services/ListsServices.js";

export default {
  props: ["username"],
  components: {
    ShowLists,
    UserCard,
    NewList,
  },
  setup(props) {
    const lists = computed(() => listsForUsername(props.username) || []);

    const canEdit = computed(
      () => currentUser.value && props.username === currentUser.value.username
    );

    const showNewList = ref(false);

    return {
      lists,
      canEdit,
      showNewList,
    };
  },
};
</script>

<template>
  <base-content>
    <template #title>
      <UserCard :username="username" class="-mt-4" />
      <h1 class=" relative mt-0">
        <div class=" flex items-center">
          <span>Lists</span>

          <base-button class="text-xs ml-4" @click="showNewList = true">
            Create New List
          </base-button>
        </div>
      </h1>
    </template>

    <ShowLists
      :lists="lists.value"
      :username="username"
      :canEdit="canEdit"
      class=" p-2 md:p-6 grid gap-4 items-stretch md:gap-6 sm:grid-cols-2 auto-rows-min grid-flow-row-dense lg:grid-cols-3 xl:grid-cols-4"
    />
  </base-content>

  <NewList :is-open="showNewList" @close="showNewList = false" />
</template>
