import RecentActivity from "../pages/RecentActivity";

export const ActivityRoutes = [
  {
    path: "/activity",
    name: "activity",
    component: RecentActivity,
    props: true,
  },
];
