import { createClient } from "@supabase/supabase-js";

let supabase = null;

export const useSupabase = async () => {
  if (!supabase) {
    supabase = createClient(
      process.env.VUE_APP_SUPABASE_URL,
      process.env.VUE_APP_SUPABASE_PUBLIC_KEY
    );
  }

  if (
    localStorage["supabase.auth.token"] &&
    JSON.parse(localStorage["supabase.auth.token"]).expiresAt <
      Date.now() / 1000
  ) {
    console.log("refreshing sesssion");
    await supabase.auth.refreshSession();
  }
  return supabase;
};
