<template>
  <base-content>
    <template #title>
      <h1
        class="text-3xl font-semibold text-green-600  font-logo border-b-2 pb-2 border-green-600 mb-4 capitalize flex items-center"
      >
        People
      </h1>
    </template>

    <ul
      class="grid gap-4 md:gap-6 grid-cols-1 lg:grid-cols-2 auto-rows-min grid-flow-row-dense xl:grid-cols-3  p-2 md:p-6 relative max-w-screen-xl mx-auto"
    >
      <li v-for="user in users" :key="user.username">
        <UserCard
          :username="user.username"
          class="rounded-xl shadow-lg bg-white px-2 md:px-4 shadow-green-400 hover:bg-green-50 transition-colors"
        />
      </li>
    </ul>
  </base-content>
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";
import UserCard from "../components/UserCard";

export default {
  components: { UserCard },
  setup() {
    const store = useStore();
    const users = computed(() => store.getters["users/users"]);

    onMounted(() => {
      store.dispatch("users/getUsers");
    });

    return {
      users,
    };
  },
};
</script>

<style lang="postcss" scoped></style>
