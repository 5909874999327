<template>
  <form @submit.prevent="submitForm" class=" rounded-xl shadow-md ">
    <div class="flex ">
      <input
        type="text"
        v-model="nameOrUrl"
        placeholder="Quick add gift Name or Url"
        class="label text-left p-3 flex-1 bg-opacity-75 bg-green-100 border-0 placeholder-green-900 rounded-l-xl text-lg "
      />

      <button
        type="submit"
        class="py-3 px-8 flex-grow-0 flex-shrink bg-yellow-200 text-green-600 rounded-r-xl font-bold"
      >
        Add
      </button>
    </div>
  </form>
</template>

<script>
import GiftService from "../services/GiftService";
import { generateWrappingPaperFromImage } from "./WrappingPaper";
import { ref } from "vue";

export default {
  emits: ["submit"],
  setup(props, { emit }) {
    const nameOrUrl = ref("");

    const isUrl = (value) => value.match(/^https?:\/\/+./);

    const submitForm = async () => {
      const payload = {};

      if (isUrl(nameOrUrl.value)) {
        const giftData = await GiftService.fetchGiftData(nameOrUrl.value);
        payload.name = giftData.name;
        payload.image = giftData.image;
        payload.url = nameOrUrl.value;
      } else {
        payload.name = nameOrUrl.value;
        payload.image = null;
        payload.url = null;
      }

      const wrappingPaper = await generateWrappingPaperFromImage(payload.image);

      payload.wrapType = wrappingPaper.wrapType;
      payload.wrapLight = wrappingPaper.wrapLight;
      payload.wrapDark = wrappingPaper.wrapDark;

      emit("submit", payload);

      nameOrUrl.value = "";
    };

    return {
      nameOrUrl,
      submitForm,
    };
  },
};
</script>

<style scoped>
textarea:focus,
input:focus,
input[type]:focus,
.uneditable-input:focus {
  --yellow: 252, 211, 77;
  border-color: rgba(var(--yellow), 0.8);
  box-shadow: 0 1px 1px rgba(var(--yellow), 0.075) inset,
    0 0 8px rgba(var(--yellow), 0.6);
  outline: 0 none;
}
</style>
