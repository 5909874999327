<script>
import ActivityLog from "@/modules/activity/components/ActivityLog";
import { currentUser, isLoggedIn } from "@/modules/auth/services/AuthService";
import NewList from "@/modules/lists/components/NewList.vue";
import ShowLists from "@/modules/lists/components/ShowLists";
import { listsForUsername } from "@/modules/lists/services/ListsServices";
import UserCard from "@/modules/users/components/UserCard";
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  components: {
    NewList,
    UserCard,
    ActivityLog,
    ShowLists,
  },
  setup() {
    const store = useStore();
    const showNewList = ref(false);

    store.dispatch("activity/getFollowingActivity");
    const followingActivity = computed(() =>
      store.getters["activity/follows"].slice(0, 10)
    );

    const myActivity = computed(() =>
      store.getters["activity/username"](currentUser.value.username).slice(
        0,
        10
      )
    );

    const lists = computed(() => {
      if (currentUser.value.username) {
        return listsForUsername(currentUser.value.username) || [];
      }
      return [];
    });

    return {
      currentUser,
      followingActivity,
      myActivity,
      isLoggedIn,
      lists,
      showNewList,
    };
  },
};
</script>
<template>
  <base-content v-if="isLoggedIn">
    <template #title>
      <h1 class="font-logo mb-0 flex justify-between items-end">
        <span class="flex-grow">My gift.town</span>

        <UserCard
          :username="currentUser.username"
          class="-mt-8 flex-shrink w-auto"
        />
      </h1>
    </template>

    <div class="grid grid-cols-5 grid-rows-1 gap-6">
      <div class="col-span-3">
        <h2 class="flex items-center">
          Lists
          <base-button class="text-xs ml-4" @click="showNewList = true">
            Create New List
          </base-button>
        </h2>
        <ShowLists
          :lists="lists.value"
          :username="currentUser.username"
          class="grid gap-4 items-stretch md:gap-6 lg:grid-cols-2 auto-rows-min grid-flow-row-dense"
        />
      </div>
      <div class="col-span-2">
        <h2>Following Activity</h2>
        <ActivityLog
          :activity="followingActivity"
          v-if="followingActivity.length"
        />
        <p v-else>
          You're not following anyone with activity.<br /><br />
          <router-link
            :to="{ name: 'people' }"
            class="text-green-600 font-semibold"
            >Find some people</router-link
          >
          to follow.
        </p>

        <div v-if="myActivity.length">
          <h2 class="mt-8">My Activity</h2>
          <ActivityLog :activity="myActivity" />
        </div>
      </div>
    </div>
  </base-content>

  <NewList :is-open="showNewList" @close="showNewList = false" />
</template>
