<script>
import { ref, computed } from "vue";
import { useRouter } from "vue-router";
import {
  currentUser,
  isLoggedIn,
  logout,
} from "@/modules/auth/services/AuthService";
import gravatar from "gravatar";

export default {
  setup() {
    const isProfileMenuOpen = ref(false);
    const isMobileMenuOpen = ref(false);

    const router = useRouter();

    const userProfileImage = computed(() => {
      if (isLoggedIn.value === true) {
        return gravatar.url(currentUser.value.email, {
          protocol: "https",
          s: "100",
          d: "robohash",
        });
      }
      return null;
    });

    const doLogout = () => {
      isProfileMenuOpen.value = false;
      isMobileMenuOpen.value = false;
      logout();
      router.push({ name: "home" });
    };

    const navigationItems = [
      { name: "My Lists", url: "/lists" },
      { name: "People", url: "/people" },
    ];

    return {
      isLoggedIn,
      doLogout,
      currentUser,
      isProfileMenuOpen,
      isMobileMenuOpen,
      userProfileImage,
      navigationItems,
    };
  },
};
</script>

<template>
  <nav class="bg-yellow-100 ">
    <div class="max-w-7xl mx-auto px-2 sm:px-4 md:px-8">
      <div class="relative h-16 md:h-32 flex items-center justify-between">
        <div class="px-2 flex items-center md:px-0">
          <router-link
            to="/"
            class="flex-shrink-0 font-logo font-bold text-4xl text-yellow-400 flex items-center pt-2"
          >
            <img
              src="@/assets/town.svg"
              alt=""
              class="h-12 md:h-16 lg:h-24 mr-6 block -mt-2"
            />
            gift.town
          </router-link>
          <div class="hidden md:block md:ml-10">
            <div class="flex space-x-4" v-if="isLoggedIn">
              <router-link
                :to="navItem.url"
                v-for="navItem in navigationItems"
                :key="navItem.name"
                href="#"
                class="rounded-md py-2 px-3 text-sm font-medium text-green-600 hover:bg-yellow-400 hover:bg-opacity-75"
                :class="
                  $router.currentRoute.value.path.includes(navItem.url)
                    ? 'bg-yellow-300'
                    : ''
                "
              >
                {{ navItem.name }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="flex-1 px-2 flex justify-end md:ml-6  space-x-4">
          <div
            v-if="isLoggedIn"
            class="hidden md:block text-green-600 font-bold"
          >
            {{ currentUser.firstname }}
          </div>
          <router-link
            v-if="!isLoggedIn"
            to="/signup"
            class=" text-green-600 border-b-2  border-transparent hover:border-green-600 block -mb-1.5 pb-0.5 font-bold"
            >Sign Up</router-link
          >
          <router-link
            v-if="!isLoggedIn"
            to="/login"
            class=" text-green-600 border-b-2  border-transparent hover:border-green-600 block -mb-1.5 pb-0.5 font-bold"
            >Login</router-link
          >
        </div>

        <div v-if="isLoggedIn" class="flex md:hidden">
          <!-- Mobile menu button -->
          <button
            class="bg-yellow-400 p-2 rounded-md inline-flex items-center justify-center text-yellow-200 hover:text-green-600 hover:bg-yellow-400 hover:bg-opacity-75 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-600 focus:ring-green-600"
            aria-expanded="false"
            @click="isMobileMenuOpen = !isMobileMenuOpen"
          >
            <span class="sr-only">Open main menu</span>
            <!--
                Heroicon name: menu

                Menu open: "hidden", Menu closed: "block"
              -->
            <svg
              class=" h-6 w-6"
              :class="isMobileMenuOpen ? 'hidden' : 'block'"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!--
                Heroicon name: x

                Menu open: "block", Menu closed: "hidden"
              -->
            <svg
              class=" h-6 w-6"
              :class="isMobileMenuOpen ? 'block' : 'hidden'"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div v-if="isLoggedIn" class="hidden md:block md:ml-4">
          <div class="flex items-center">
            <button
              class="bg-yellow-400 flex-shrink-0 rounded-full p-1 text-yellow-200 hover:text-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-600 focus:ring-green-600"
            >
              <span class="sr-only">View notifications</span>
              <!-- Heroicon name: bell -->
              <svg
                class="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </button>

            <!-- Profile dropdown -->
            <div class="ml-3 relative flex-shrink-0">
              <div>
                <button
                  class="bg-yellow-600 rounded-full flex text-sm text-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-600 focus:ring-green-600"
                  id="user-menu"
                  aria-haspopup="true"
                  @click="isProfileMenuOpen = !isProfileMenuOpen"
                >
                  <span class="sr-only">Open user menu</span>
                  <img
                    class="rounded-full h-8 w-8 bg-white border border-black"
                    :src="userProfileImage"
                    alt=""
                  />
                </button>
              </div>
              <!--
                  Profile dropdown panel, show/hide based on dropdown state.

                  Entering: "transition ease-out duration-100"
                    From: "transform opacity-0 scale-95"
                    To: "transform opacity-100 scale-100"
                  Leaving: "transition ease-in duration-75"
                    From: "transform opacity-100 scale-100"
                    To: "transform opacity-0 scale-95"
                -->
              <div
                class="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-green-600 ring-1 ring-black ring-opacity-5"
                role="menu"
                v-if="isProfileMenuOpen"
                aria-orientation="vertical"
                aria-labelledby="user-menu"
                @mouseleave="isProfileMenuOpen = false"
              >
                <router-link
                  :to="{ name: 'profile' }"
                  class="block py-2 px-4 text-sm text-white hover:text-black hover:bg-yellow-50"
                  role="menuitem"
                >
                  Your Profile
                </router-link>

                <a
                  @click="doLogout"
                  class="block py-2 px-4 text-sm text-white hover:text-black hover:bg-yellow-50"
                  role="menuitem"
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
        Mobile menu, toggle classes based on menu state.

        Menu open: "block", Menu closed: "hidden"
      -->
    <div class="md:hidden" :class="isMobileMenuOpen ? 'block' : 'hidden'">
      <div class="px-2 pt-2 pb-3 space-y-1" v-if="isLoggedIn">
        <router-link
          :to="navItem.url"
          v-for="navItem in navigationItems"
          :key="navItem.name"
          href="#"
          class="block rounded-md py-2 px-3 text-base font-medium text-green-600"
          :class="
            $router.currentRoute.value.path.includes(navItem.url)
              ? 'bg-yellow-300'
              : ''
          "
        >
          {{ navItem.name }}
        </router-link>
      </div>
      <div class="pt-4 pb-3 border-t border-yellow-400" v-if="isLoggedIn">
        <div class="px-5 flex items-center">
          <div class="flex-shrink-0">
            <img
              class="rounded-full h-10 w-10  bg-white border border-black"
              :src="userProfileImage"
              alt=""
            />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium text-green-600">
              {{ currentUser.firstname }} {{ currentUser.lastname }}
            </div>
            <div class="text-sm font-bold text-yellow-400">
              @{{ currentUser.username }}
            </div>
          </div>
          <button
            class="ml-auto bg-yellow-400 flex-shrink-0 rounded-full p-1 text-yellow-200 hover:text-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-600 focus:ring-green-600"
          >
            <span class="sr-only">View notifications</span>
            <!-- Heroicon name: bell -->
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
          </button>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <a
            href="#"
            class="block rounded-md py-2 px-3 text-base font-medium text-green-600 hover:bg-yellow-400 hover:bg-opacity-75"
          >
            Your Profile
          </a>

          <a
            href="#"
            class="block rounded-md py-2 px-3 text-base font-medium text-green-600 hover:bg-yellow-400 hover:bg-opacity-75"
          >
            Settings
          </a>

          <button
            @click="doLogout"
            class="block rounded-md py-2 px-3 text-base font-medium text-green-600 hover:bg-yellow-400 hover:bg-opacity-75"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>
