<template>
  <base-content>
    <template #workarea>
      <base-card class="max-w-screen-sm my-8 bg-green-50">
        <h1
          class="text-left text-3xl  font-semibold border-green-600 text-green-600 pb-4 mb-4 border-b-2 "
        >
          Login
        </h1>
        <form
          @submit.prevent="handleLogin"
          class="flex flex-col  relative max-w-screen-sm mx-auto"
        >
          <div class="field">
            <label for="email">Email</label>
            <input type="email" id="email" v-model="email" />
          </div>

          <div class="field">
            <label for="password">Password</label>
            <input type="password" id="password" v-model="password" />
          </div>
          <p v-if="error" class="text-red-500 text-sm">
            <strong>Error:</strong> {{ error }}
          </p>
          <base-button type="submit" class="mt-2">Login</base-button>
        </form>
      </base-card>
    </template>
  </base-content>
</template>

<script>
import { ref } from "vue";
import { login } from "@/modules/auth/services/AuthService";
import { useRouter } from "vue-router";

export default {
  setup() {
    const email = ref("");
    const password = ref("");
    const error = ref("");

    const router = useRouter();

    const handleLogin = async () => {
      try {
        await login(email.value, password.value);
        router.push({
          path: "/",
        });
      } catch (err) {
        error.value = err.message;
      }
    };

    return {
      email,
      password,
      handleLogin,
      error,
    };
  },
};
</script>
