<template>
  <base-modal
    class="max-w-screen-sm my-8"
    @close="emitClose()"
    :is-open="isOpen"
  >
    <h1>Edit List</h1>

    <form
      @submit.prevent="updateList"
      class="flex flex-col  relative max-w-screen-sm mx-auto"
    >
      <base-field
        type="text"
        label="Name"
        v-model="editingList.name"
        id="name"
      />

      <fieldset>
        <legend>Settings</legend>

        <base-toggle
          v-model="editingList.setting_self_claimable"
          :invert="true"
          label="For Me"
          description=" (Hides what has been claimed from you)"
          class="my-4"
        />

        <base-toggle
          v-model="editingList.setting_claimable"
          label="Claiming"
          description=" (Others can claim items off this list)"
          class="my-4"
        />

        <base-toggle
          v-model="editingList.setting_private"
          label="Private"
          description=" (Other's can't see this list)"
          class="my-4"
        />
      </fieldset>

      <p v-if="error" class="text-red-500 text-sm">
        <strong>Error:</strong> {{ error }}
      </p>
      <base-button type="submit">Update List</base-button>
    </form>
  </base-modal>
</template>

<script>
import { ref, reactive, computed, watch } from "vue";
import { useStore } from "vuex";
import slugify from "slugify";
// import { currentUser } from "@/modules/auth/services/AuthService";

export default {
  emits: ["close", "updated"],
  props: { username: String, listSlug: String, isOpen: Boolean },
  setup(props, { emit }) {
    const store = useStore();

    const list = computed(() =>
      store.getters["lists/list"](props.username, props.listSlug)
    );

    let editingList = reactive({});
    watch(list, () => {
      if (list.value) {
        editingList.id = list.value.id;
        editingList.name = list.value.name;
        editingList.slug = list.value.slug;
        editingList.setting_self_claimable = list.value.setting_self_claimable;
        editingList.setting_claimable = list.value.setting_claimable;
        editingList.setting_private = list.value.setting_private;
      }
    });

    const error = ref("");

    store.dispatch("lists/getList", {
      username: props.username,
      slug: props.listSlug,
    });

    const updateList = async () => {
      try {
        if (editingList.name.value === "") {
          error.value = "Please enter a list name";
          return false;
        }

        const slug = slugify(editingList.name, { lower: true });

        const updates = {
          id: editingList.id,
          name: editingList.name,
          slug: slug,
          setting_self_claimable: editingList.setting_self_claimable,
          setting_claimable: editingList.setting_claimable,
          setting_private: editingList.setting_private,
        };

        const updatedList = await store.dispatch("lists/updateList", {
          id: editingList.id,
          updates,
        });

        emit("updated", updatedList);
      } catch (err) {
        error.value = err.message;
      }
    };

    const emitClose = () => {
      emit("close");
    };

    return {
      error,
      list,
      updateList,
      editingList,
      emitClose,
    };
  },
};
</script>

<style lang="postcss" scoped>
fieldset {
  @apply border border-green-600 px-6 py-4  my-4;
  legend {
    @apply text-green-600 font-medium px-2 -ml-2;
  }
}
</style>
