import { computed } from "vue";
import { useStore } from "vuex";

export function listsForUsername(username) {
  const store = useStore();
  store.dispatch("lists/getLists", { username });

  const lists = computed(() => {
    return store.getters["lists/lists"](username);
  });

  return lists;
}
