import Lists from "@/modules/lists/pages/Lists.vue";
import List from "@/modules/lists/pages/List.vue";
import {
  isLoggedIn,
  currentUser,
  init as authInit,
} from "@/modules/auth/services/AuthService";

export const ListRoutes = [
  {
    path: "/lists",
    beforeEnter: async (to, from, next) => {
      await authInit();
      if (isLoggedIn.value) {
        next({
          name: "lists",
          params: { username: currentUser.value.username },
        });
      } else {
        next("/");
      }
    },
  },
  {
    path: "/lists/:username",
    name: "lists",
    component: Lists,
    props: true,
  },
  {
    path: "/lists/:username/:listSlug",
    name: "list",
    component: List,
    props: true,
  },
];
