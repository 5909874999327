import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

import BaseModal from "@/components/Layout/BaseModal";
import BaseCard from "@/components/Layout/BaseCard";
import BaseContent from "@/components/Layout/Content/BaseContent";
import BaseButton from "@/components/Layout/Buttons/BaseButton";
import BaseToggle from "@/components/Layout/Buttons/BaseToggle";
import BaseField from "@/components/Layout/Fields/BaseField";

createApp(App)
  .component("base-button", BaseButton)
  .component("base-card", BaseCard)
  .component("base-modal", BaseModal)
  .component("base-content", BaseContent)
  .component("base-toggle", BaseToggle)
  .component("base-field", BaseField)
  .use(store)
  .use(router)
  .mount("#app");
